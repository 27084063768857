import 'isomorphic-fetch';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import {Helmet} from 'react-helmet';
import 'regenerator-runtime/runtime';
import React, {useEffect, useMemo, useRef, Suspense} from 'react';
import './CollectionPage.scss';
import smoothscroll from 'smoothscroll-polyfill';
import CollectionVideo from "../../img/moodwater/moodwater_video_pc.mp4"
import {CartWithNumberDt, CartWithNumberMo} from '../../assets/CartWithNumber.jsx';
import {categoryNameTranslateKR, moveToLink} from '../utils/utils.js';
import Header from '../components/Header/Header.component';
import HambergerForHiddenNav from '../components/HambergerForHiddenNav/HambergerForHiddenNav.component';

import {BackwordArrow} from '../../assets/Arrows';
import HomeIcon from "../../img/gnb_home_32.svg"
import {breakpointDt} from '../constants/constants';
import CollectionSelector from '../components/CollectionSelector/CollectionSelector.component';
import CollectionSelectorTop from '../components/CollectionSelector/CollectionSelectorTop.component';
import CollectionSortControll from '../components/CollectionSortControll/CollectionSortControll.component';
import CollectionItemList from '../components/CollectionItemList/CollectionItemList.component';
import SpinnerControl from '../components/SpinnerControl/SpinnerControl';
import {AppContext} from '../context/AppContext';
import {observer} from 'mobx-react';
import "swiper/swiper-bundle.css"
import {Swiper, SwiperSlide} from 'swiper/react';

import {useParams} from "react-router";
import {useLocation, useNavigate} from "react-router-dom";

const CollectionPage = observer(() => {
    smoothscroll.polyfill();
    const {collectionPageStore, spinnerStore, cartDataStore, bannerDataStore, userDataStore} =
        React.useContext(AppContext);
    const {isLogin} = userDataStore
    const navigate = useNavigate()
    const controlPositionRef = useRef(99999999);
    const {categoryName} = useParams()
    const location = useLocation();
    const subCollection = new URLSearchParams(location.search).get('subcollection')
    const FooterIncenseVer = useMemo(
        () => React.lazy(() => import('../components/Footer/FooterIncenseVer.component')),
        []
    );

    useEffect(() => {
        const handleControlPosition = () => {
            const collectionControl = document.getElementById('collectionControl');
            if (controlPositionRef && collectionControl) {
                controlPositionRef.current = Math.min(
                    controlPositionRef.current,
                    collectionControl.offsetTop
                );
            }

            const collectionControlPosition = collectionControl?.getBoundingClientRect();
            const header = document.querySelector('.mainHeaderContainer');
            const headerScrollHeight = header?.scrollHeight;

            if (collectionControlPosition?.top <= headerScrollHeight) {
                collectionPageStore.setIsControlSticky(true);
            } else if (
                window.scrollY < controlPositionRef?.current &&
                (collectionControlPosition?.top > headerScrollHeight + 20 ||
                    collectionControlPosition?.top < 0)
            ) {
                collectionPageStore.setIsControlSticky(false);
            }
        };

        const handleControlStickyControlDistance = () => {
            const collectionControl = document.getElementById('collectionControl');
            const header = document.querySelector('.mainHeaderContainer') as HTMLDivElement;
            if (window.innerWidth > breakpointDt) {
                if (collectionControl && header) {
                    collectionControl.style.top = `${header.scrollHeight - 2}px`;
                    header.style.backgroundColor = '#fffff5';
                }
                // 헤더와 범위가 벌어지는 문제가 생겨 2픽셀을 임의로 위로 올림
            } else {
                if (collectionControl && header) {
                    collectionControl.style.top = '0px';
                    header.style.backgroundColor = 'inherit';
                }
            }
        };

        window.addEventListener('scroll', handleControlPosition);
        window.addEventListener('resize', handleControlStickyControlDistance);

        setTimeout(handleControlPosition, 1000);
        handleControlStickyControlDistance();

        return () => {
            window.removeEventListener('scroll', handleControlPosition);
            window.removeEventListener('resize', handleControlStickyControlDistance);
        };
    }, []);

    const matchCategoryId = () => {
        const filterData = collectionPageStore.allCollections.filter(item => item.url === categoryName)
        if (filterData[0]?.id) {
            return filterData[0].id
        } else {
            return navigate('/error')
        }
    }

    useEffect(() => {
        initSubCategory()
    }, [categoryName])

    const initSubCategory = () => {
        const targetData = collectionPageStore.allCollections.filter(item => item.url === categoryName)
        if (targetData.length > 0) {
            collectionPageStore.setCollectionNow(targetData[0])
        }
        if (subCollection) {
            const subCategory = targetData[0]?.subCategories?.filter(subCategory => subCategory.slug === subCollection)[0]
            if (subCategory) {
                const collectionNow = collectionPageStore.collectionNow;
                collectionNow.subCategory = subCategory;
                collectionPageStore.setCollectionNow(
                    JSON.parse(JSON.stringify(collectionNow))
                );
            }
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        if (collectionPageStore.allCollections.length > 0) {
            collectionPageStore.callCollectionItems(matchCategoryId());
        }

    }, [collectionPageStore.collectionNow, collectionPageStore.sortCriteria]);

    useEffect(() => {
        cartDataStore.callCartCount();
        bannerDataStore.callTopBannerInfo();
        collectionPageStore.callAllCollections().then(() => initSubCategory())
        const scrollHandler = () => {
            const headLogo = document.querySelector('.header-logo.dt');
            if (headLogo) {
                if (window.scrollY > 15) {
                    headLogo?.classList.add('transparent');
                } else {
                    headLogo?.classList.remove('transparent');
                }
            }
        };
        scrollHandler();

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);


    const LazyLoadedVideo = React.lazy(() => import('../components/CollectionMoodwaterVideo/CollectionMoodwaterVideo.component'));

    return (
        <SpinnerControl isSpinnerShow={spinnerStore.isSpinnerShow}>
            <Helmet>
                <title>콜린스
                    - {collectionPageStore.collectionNow.title ? collectionPageStore.collectionNow.title : ""}</title>
            </Helmet>
            <div className={`collectionPage ${categoryName === "mood" ? "collectionPage-moodwater" : ""}`}>
                <Header
                    callCartCount={cartDataStore.callCartCount}
                    // 사실 아래의 props는 필요 없어짐 -> 진짜 될지 해보자
                    // setIsSpinnerShow = {spinnerStore.isSpinnerShow}
                    topBannerData={{data: bannerDataStore.topBannerData}}
                    topBannerEnabled={
                        bannerDataStore.topBannerData && bannerDataStore.topBannerData.enabled
                    }
                    isHeaderBgColorChange={true}
                    leftContent={
                        <>
                            <HambergerForHiddenNav className="dt"/>
                            <div className="mo">
                                {document.referrer && document.referrer.indexOf('collinslife.co') != -1 ? (
                                    <div
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            history.go(-1);
                                        }}
                                    >
                                        <BackwordArrow/>
                                    </div>
                                ) : (
                                    <div
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                    >
                                        <img src={HomeIcon} alt=""/>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                    centerContent={
                        <>
                            <img
                                src="https://fly.gitt.co/collins/img/logo_b.svg"
                                className="header-logo fixed_logo header-logo-dt dt"
                                style={{
                                    pointerEvents: 'all',
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                    navigate('/')
                                }}
                            />
                        </>
                    }
                    rightContent={
                        <>
                            <div
                                className=" dt"
                                onClick={() => {
                                    navigate('/cart');
                                }}
                            >
                                <CartWithNumberDt number={cartDataStore.cartQuantity}/>
                            </div>
                            <div
                                className="flex  mo"
                                // style ={{display: 'flex'}}
                            >
                                <div
                                    className="mo "
                                    onClick={() => {
                                        navigate('/cart');
                                    }}
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    <CartWithNumberMo number={cartDataStore.cartQuantity}/>
                                </div>
                                <HambergerForHiddenNav/>
                            </div>
                        </>
                    }
                />

                <div className="collectionHeadComponent">
                    {categoryName === "mood" &&
                        <Suspense fallback={<div className="collectionHeadComponent-moodwater-background"></div>}>
                            <LazyLoadedVideo/>
                        </Suspense>}
                    <div className={categoryName === "mood" ? `collectionHeadComponent-text-wrapper` : ""}>
                        <div
                            className="decoText"
                            style={{
                                opacity: collectionPageStore.titleDescriptionDefault ? 1 : 0,
                                transform: collectionPageStore.titleDescriptionDefault
                                    ? 'unset'
                                    : 'translateY(20px)',
                                transition: 'transform 800ms',
                            }}
                        >
                            {collectionPageStore.collectionNow.titleDescription
                                ? collectionPageStore.collectionNow.titleDescription
                                : collectionPageStore.titleDescriptionDefault}
                        </div>
                        <div className="selectorPosition">
                            <CollectionSelectorTop/>
                        </div>
                    </div>

                </div>

                <div className="subMenuArea">
                    <Swiper
                        spaceBetween={16}
                        slidesPerView={'auto'}
                        updateOnWindowResize={true}
                        loop={false}
                        freeMode={true}
                        slidesOffsetAfter={0}
                        slidesOffsetBefore={0}
                        navigation={{
                            nextEl: '.swiper-button-next-content.mainType1',
                            prevEl: '.swiper-button-prev-content.mainType1',
                        }}
                    >
                        {collectionPageStore.collectionNow &&
                            collectionPageStore.collectionNow.subCategories &&
                            collectionPageStore.collectionNow.subCategories.length > 0 &&
                            collectionPageStore.collectionNow.subCategories.map((subCategory, i) => {
                                const isLastIdx = i == collectionPageStore.collectionNow.subCategories.length - 1;
                                return (
                                    <SwiperSlide
                                        key={`subSlide${i}`}
                                        className={
                                            collectionPageStore.collectionNow.subCategory &&
                                            collectionPageStore.collectionNow.subCategory.slug == subCategory.slug
                                                ? 'on'
                                                : ''
                                        }
                                    >
                                        <div
                                            className="subMenu"
                                            style={{marginRight: isLastIdx ? '-16px' : '0px'}}
                                            onClick={() => {
                                                const collectionNow = collectionPageStore.collectionNow;
                                                collectionNow.subCategory = subCategory;
                                                collectionPageStore.setCollectionNow(
                                                    JSON.parse(JSON.stringify(collectionNow))
                                                );
                                                if (subCategory.slug !== "") {
                                                    navigate(`${location.pathname}?subcollection=${subCategory.slug}`)
                                                } else {
                                                    navigate(`${location.pathname}`)
                                                }
                                            }}
                                        >
                                            {subCategory.name}
                                            <div className="bottom-border"></div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>

                <div
                    className={`collectionControl ${categoryName === "mood" ? "collectionControl-moodwater" : ""}`}
                    id="collectionControl">
                    <div className="totalCount"
                         style={{display: !collectionPageStore?.isControlSticky ? "block" : "none"}}>
                        {collectionPageStore?.totalItemCnt > 0 && (
                            <>
                                총 <strong>{collectionPageStore.totalItemCnt}개</strong> 상품{' '}
                            </>
                        )}
                    </div>
                    <div className="selectorOnSticky "
                         style={{display: !collectionPageStore?.isControlSticky ? "none" : "flex"}}>
                        <CollectionSelector/>
                    </div>
                    <CollectionSortControll
                        curSort={collectionPageStore.sortCriteria}
                        setSortCriteria={collectionPageStore.setSortCriteria}
                    />
                </div>
                <div className="collectionItems">
                    <CollectionItemList
                        moodwater={categoryName === "mood"}
                        isLogin={isLogin}
                        collectionNow={collectionPageStore.collectionNow}
                        listItem={collectionPageStore.collectionItems}
                        collectionPromoText={collectionPageStore.collectionPromoText}
                        callCartCount={cartDataStore.callCartCount}
                        setIsSpinnerShow={spinnerStore.setIsSpinnerShow}
                        categoryName={categoryName}
                    />
                </div>
            </div>

            {/* <Footer /> */}
            <Suspense fallback={<div></div>}>
                <FooterIncenseVer/>
            </Suspense>
        </SpinnerControl>
    );
});

export default CollectionPage
