import 'isomorphic-fetch';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React, {useState, useEffect, useMemo, Suspense, useRef} from 'react';
import ProductPurchaseMenu from '../components/PurchaseMenu/ProductPurchaseMenu.component.jsx';
import DetailImages from '../components/DetailImages/DetailImages.component.jsx';
import ProductDetailMobileFloatingButtons
    from '../components/ProductDetailMobileFloatingButtons/ProductDetailMobileFloatingButtons.component.jsx';
import './ProductPage.scss';
import {CartWithNumberDt, CartWithNumberMo} from '../../assets/CartWithNumber';
import {BackwordArrow, DownLoadArrow} from '../../assets/Arrows';
import Modal from '../components/Modal/Modal.component.jsx';
import NavigationTab from '../components/NavigationTab/NavigationTab.component.jsx';
import {customTaps, cartAddurl, breakpointDt_mid, apiUrl} from '../constants/constants';

import {
    fetchAndReceiveJSON,
    moveToAnchor,
    moveToLink,
    isHasCustomTapProduct,
    filterThumbnailImageByFormat, createCh, deleteCh
} from '../utils/utils.js';

import {buyNowNpayAction, getProductsForNpayZzim, makeNpayButton} from '../utils/paymentUtils';
import SpinnerControl from '../components/SpinnerControl/SpinnerControl.jsx';
import smoothscroll from 'smoothscroll-polyfill';
import CTAButton from '../components/CTAButton/CTAButton.component.jsx';
import {WhiteCloseIcon, MediumBrownCloseIcon} from '../../assets/CloseIcon';
import {breakpointDt, NpayButtonKey} from '../constants/constants.js';
import HambergerForHiddenNav from '../components/HambergerForHiddenNav/HambergerForHiddenNav.component';
import Header from '../components/Header/Header.component';
import PreloadImage from 'react-preload-image';
import FooterIncenseVer from '../components/Footer/FooterIncenseVer.component';
import {AppContext} from "../context/AppContext";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import GnbHomeIcon from "../../img/gnb_home_32.svg"
import {observer} from "mobx-react";
import RestockingModal from "../components/RestockingModal/RestockingModal";

const C_IAMPORT_CODE = process.env.REACT_APP_IAMPORT_CODE;

const productImg = {product: {images: [{src: ''}, {src: ''}, {src: ''}]}};

const CURRENT_MENU_POSITION_RELATIVE = 'relative';
const CURRENT_MENU_POSITION_FIXED = 'fixed';
const CLASS_MENU_POSITION_RELATIVE = 'scroll';

const ProductPage = () => {
    // scrollPolyfill();
    smoothscroll.polyfill();
    const C_COUPONS = null;
    const [isSpinnerShow, setIsSpinnerShow] = useState(false);
    const location = useLocation();
    const pathArr = location.pathname.split('/');
    const [productData, setProductData] = useState(productImg);
    const productDataRef = useRef(productData);
    const navigate = useNavigate();
    const {userDataStore, cartDataStore, bannerDataStore} = React.useContext(AppContext);
    const {callCartCount, cartQuantity} = cartDataStore
    const [targetProductId] = useState(pathArr[pathArr.length - 1])
    const {isLogin, user} = userDataStore

    const [optionInfo, setOptionInfo] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [onePurchaseModal, setOnePurchaseModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [relatedProductsData, setRelatedProductsData] = useState([]);
    const [scrollBefore, setScrollBefore] = useState(0);
    // const [scrollDirection, setScrollDirection] = useState('down');
    const [makeNpayButtonFlag, setMakeNpayButtonFlag] = useState(false);
    // const [totalOptionCount, setTotalOptionCount] = useState(0);
    const [isUpSellingPopUp, setIsUpSellingPopUp] = useState(false);
    const [upSellDiscountRate, setUpSellDiscountRate] = useState('%');
    const [upSellText1, setUpSellText1] = useState('');
    const [upSellText2, setUpSellText2] = useState('');
    const [upSellLink, setUpSellLink] = useState('');
    const [upSellImage, setUpSellImage] = useState();
    const [detailPageLoaded, setDetailPageLoaded] = useState(true);
    const [upSellButtonText, setUpSellButtonText] = useState('');
    const [tabInfo, setTabInfo] = useState([
        {
            tabName: '제품 정보',
            targetDomId: 'detail',
            moveWhenClicked: true,
        },
        {
            tabName: '리뷰',
            targetDomId: 'review',
            moveWhenClicked: true,
        },
        {
            tabName: '연관상품',
            targetDomId: 'related',
            moveWhenClicked: true,
        },
    ]);
    const [topBannerInfo] = useState({});
    const [eventInfo, setEventInfo] = useState();
    const [eventBannerData, setEventBannerData] = useState();
    const [loginModal, setLoginModal] = useState(false);
    const [loadedThumbnail, setLoadedThumbnail] = useState(false)
    const [pageInfo, setPageInfo] = useState({
        scrollParent: null,
        altScrollParent: null,
        scrollPosition: () => {
            return 0;
        },
    });

    const gteThumbnailIamges = []
    const [cartModal, setCartModal] = useState(false)
    const [customAlert, setCustomAlert] = useState(null);

    const defaultNavRef = useRef();

    const [currentMenuPosition, setCurrentMenuPosition] = useState(CURRENT_MENU_POSITION_FIXED);

    useEffect(() => {
        productDataRef.current = productData;
    }, [productData]);
    const currentMenuPositionRef = useRef(currentMenuPosition);
    useEffect(() => {
        currentMenuPositionRef.current = currentMenuPosition;
    }, [currentMenuPosition]);

    const scrollBeforeRef = useRef(scrollBefore);

    const DetailInfo = useMemo(
        () => React.lazy(() => import("../components/DetailInfo/DetailInfo.jsx")),
        [productData]
    );

    const Reviews = useMemo(
        () => React.lazy(() => import('../components/Reviews/Reviews.component.jsx')),
        [productData]
    );
    const PopupWrapper = useMemo(
        () => React.lazy(() => import('../components/PopupWrapper/PopupWrapper.component.jsx')),
        [productData]
    );
    const RelatedProducts = useMemo(
        () => React.lazy(() => import('../components/RelatedProducts/RelatedProducts.component.jsx')),
        [relatedProductsData]
    );


    const desktopMenuTopMargin = 88;
    // const npayBoxHeight = 85;

    //banner
    // const callTopBannerInfo = async () => {
    //         // const data = await fetchAndReceiveJSON({
    //         //     url: '/api/top-line-banner',
    //         //     method: 'GET',
    //         //     body: '',
    //         // });
    //         // setTopBannerInfo(data);
    // };
    const pageInfoRef = useRef(pageInfo);
    const definePageInfo = () => {
        //dt

        defineCurrentMenuPosition();

        if (window.innerWidth > breakpointDt) {
            createCh()
            if (currentMenuPositionRef.current === CURRENT_MENU_POSITION_RELATIVE) {
                setPageInfo({
                    scrollParent: document.querySelector('.content-area'),
                    altScrollParent: window,
                    scrollPosition: function () {
                        return document.querySelector('.content-area').scrollTop;
                    },
                });
            } else {
                setPageInfo({
                    scrollParent: window,
                    altScrollParent: document.querySelector('.content-area'),
                    scrollPosition: function () {
                        return window.scrollY;
                    },
                });
            }
        } else {
            deleteCh()
            //mb
            setPageInfo({
                scrollParent: window,
                altScrollParent: document.querySelector('.content-area'),
                scrollPosition: function () {
                    return window.scrollY;
                },
            });
        }
    };

    useEffect(() => {
        pageInfoRef.current = pageInfo;
    }, [pageInfo]);

    useEffect(() => {
        // document.title = `콜린스${productData.product?.name ? " - " + productData?.product?.name : ''}`
        setTimeout(() => {
            definePageInfo();
        }, 500);
    }, [productData]);

    const defineCurrentMenuPosition = () => {
        if (window.innerWidth <= breakpointDt) return;

        const bfMargin = 0;

        const menuInner = document.querySelector('.menu-area .menu-area-inner');
        const menuHeight = menuInner?.getBoundingClientRect().height + desktopMenuTopMargin + bfMargin;
        const menu = document.querySelector('.menu-area');
        const content = document.querySelector('.content-area');
        const page = document.querySelector('.product-page-wrapper');

        if (window.innerHeight > menuHeight) {
            //console.log('to fixed');

            if (currentMenuPositionRef.current !== CURRENT_MENU_POSITION_FIXED) {
                setCurrentMenuPosition(CURRENT_MENU_POSITION_FIXED);
                menuInner?.classList.add(CURRENT_MENU_POSITION_FIXED);
                page?.classList.remove(CLASS_MENU_POSITION_RELATIVE);
                menu?.classList.remove(CLASS_MENU_POSITION_RELATIVE);
                content?.classList.remove(CLASS_MENU_POSITION_RELATIVE);
            }
        } else {
            if (currentMenuPositionRef.current !== CURRENT_MENU_POSITION_RELATIVE) {
                setCurrentMenuPosition(CURRENT_MENU_POSITION_RELATIVE);

                menuInner?.classList.remove(CURRENT_MENU_POSITION_FIXED);
                page?.classList.add(CLASS_MENU_POSITION_RELATIVE);
                menu?.classList.add(CLASS_MENU_POSITION_RELATIVE);
                content?.classList.add(CLASS_MENU_POSITION_RELATIVE);
            }
        }
    };

    useEffect(() => {
        // console.log('update check');
        // callTopBannerInfo();

        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        const menu = document.querySelector('.menu-area');
        const content = document.querySelector('.content-area');

        window.addEventListener('resize', definePageInfo);
        menu.addEventListener('mousewheel', menuAreaHandler);
        content.addEventListener('mousewheel', contentHandler);

        return () => {
            window.removeEventListener('resize', definePageInfo);
            menu.removeEventListener('mousewheel', menuAreaHandler);
            content.removeEventListener('mousewheel', contentHandler);
            createCh();
        };

        // alert('new');
    }, []);

    const callUpSellingPopUp = () => {
        const sessionCheckForACard = sessionStorage.getItem('didUpSellingPopUpACard');
        const sessionCheckFor5Set = sessionStorage.getItem('didUpSellingPopUp5Set');

        if (!sessionCheckForACard && productData.product.slug === 'a-card') {
            sessionStorage.setItem('didUpSellingPopUpACard', true);
            setIsUpSellingPopUp(true);
            return;
        }

        if (!sessionCheckFor5Set && productData.product.slug === 'a-card-5-pack') {
            sessionStorage.setItem('didUpSellingPopUp5Set', true);
            setIsUpSellingPopUp(true);
            return;
        }

        // setIsUpSellingPopUp(true)
    };

    const calculateTotal = () => {
        let totalPrice = 0;
        let totalCount = 0;
        optionInfo.forEach((option) => {
            totalPrice += Number(option.price.replaceAll(',', '').replace('원', '')) * option.quantity;
            totalCount += option.quantity;
        });

        setTotalPrice(totalPrice);
        // setTotalOptionCount(totalCount);
        if (productData && productData.product.slug === 'a-card') {
            if (totalCount >= 3) {
                callUpSellingPopUp();
            }
        } else if (productData && productData.product.slug === 'a-card-5-pack') {
            if (totalCount >= 2) {
                callUpSellingPopUp();
            }
        }
    };

    const resetOptionInfo = () => {
        setOptionInfo([])
    }

    const adjustIndividualOptionCount = async ({
                                                   id,
                                                   name,
                                                   cnt,
                                                   img,
                                                   regularPrice,
                                                   price,
                                                   discountRate,
                                                   onSale,
                                                   isDirectChange,
                                                   product_id,
                                                   additional_info,
                                               }) => {
        // setRnadkey(Math.random());
        let isExist = false;

        optionInfo.forEach((item) => {
            if (item && item.id === id) {
                isExist = true;
            }
        });

        if (isDirectChange) {
            let newOptionInfo = [];
            optionInfo.forEach((option) => {
                if (option && option.id === id) {
                    if (additional_info?.one_purchased) {
                        if (cnt === 1) {
                            newOptionInfo.push({...option, quantity: option.quantity})
                            setOnePurchaseModal(false);
                            return;
                        }
                    }
                    if (option.quantity + cnt > 0) {
                        newOptionInfo.push({...option, quantity: cnt});
                    }
                } else {
                    // 아이디가 다른건 그냥 둔다.
                    newOptionInfo.push(option);
                }
            });
            //optionInfo = newOptionInfo;

            setOptionInfo([...newOptionInfo]);
            // calculateTotal();
            return;
        }

        if (isExist) {
            // 0개가 들어오면 삭제
            if (cnt === 0) {
                // deleteOption(id);
                const newOptionInfo = optionInfo.filter((option) => option && option.id !== id);

                // console.log("newOptionInfo" , [...newOptionInfo]);
                setOptionInfo([...newOptionInfo]);
                return;
            }

            let newOptionInfo = [];
            optionInfo.forEach((option) => {
                if (option && option.id === id) {
                    if (additional_info?.one_purchased) {
                        if (cnt === 1) {
                            newOptionInfo.push({...option, quantity: option.quantity})
                            setOnePurchaseModal(false);
                            return;
                        }
                    }
                    // 마이너스 값이 들어오는 상황을 고려, 개수가 0 이하면 없어진다. => 더이상 사용하지 않는 기능
                    if (option.quantity + cnt > 0) {
                        newOptionInfo.push({...option, quantity: option.quantity + cnt});
                    }
                } else {
                    // 아이디가 다른건 그냥 둔다.
                    newOptionInfo.push(option);
                }
            });

            setOptionInfo([...newOptionInfo]);
        } else {
            optionInfo.push({
                id: id,
                name: name,
                quantity: 1,
                img: img,
                regularPrice: regularPrice,
                price: price,
                discountRate: discountRate,
                onSale: onSale,
                product_id: product_id,
                additional_info: additional_info
            });
            // 여기까지만 optionInfo로 인지를 하고 다음 차례부터는 아예 다른걸 가져가는 듯, 다음 push부터는 완전 다른 push 니까

            setOptionInfo([...optionInfo]);
            // calculateTotal();
        }
    };

    useEffect(() => {
        if (productData.product && productData.product.type === 'simple') {
            if (optionInfo.length < 1) {
                adjustIndividualOptionCount({
                    name: productData.product.name,
                    quantity: 1,
                    regularPrice: productData.product.display_regular_price,
                    price: productData.product.display_price,
                    discountRate: productData.product.discount_rate,
                    additional_info: productData.product.additional_info
                });
                // calculateTotal();
            }
        }
    }, [productData]);

    const menuAreaHandler = (e) => {
        if (currentMenuPositionRef.current === CURRENT_MENU_POSITION_FIXED) {
            return;
        }

        const menu = document.querySelector('.menu-area');
        const content = document.querySelector('.content-area');

        const deltaY = e.deltaY;

        if (menu.scrollTop === 0 && menu.scrollHeight <= menu.offsetHeight + menu.scrollTop) {
            content.scrollTop += deltaY;
            e.preventDefault();
        } else if (menu.scrollTop === 0) {
            if (deltaY < 0) {
                content.scrollTop += deltaY;

                if (content.scrollTop > 0) {
                    e.preventDefault();
                }
            }
        } else if (menu.scrollHeight <= menu.offsetHeight + menu.scrollTop) {
            if (deltaY > 0) {
                content.scrollTop += deltaY;

                if (content.scrollHeight <= content.offsetHeight + content.scrollTop !== true) {
                    e.preventDefault();
                }
            }
        }
    };

    const contentHandler = (e) => {
        if (currentMenuPositionRef.current === CURRENT_MENU_POSITION_FIXED) {
            return;
        }
        //const menuBottom = menuArea.getBoundingClientRect().bottom;
        const menu = document.querySelector('.menu-area');
        const content = document.querySelector('.content-area');

        if (window.innerWidth <= breakpointDt) {
            return;
        }
        const deltaY = e.deltaY;

        if (
            content.scrollTop === 0 &&
            content.scrollHeight <= content.offsetHeight + content.scrollTop
        ) {
            menu.scrollTop += deltaY;
            e.preventDefault();
        } else if (content.scrollTop === 0) {
            if (deltaY < 0) {
                menu.scrollTop += deltaY;

                if (menu.scrollTop > 0) {
                    e.preventDefault();
                }
            }
        } else if (content.scrollHeight <= content.offsetHeight + content.scrollTop) {
            if (deltaY > 0) {
                menu.scrollTop += deltaY;

                if (menu.scrollHeight <= menu.offsetHeight + menu.scrollTop !== true) {
                    e.preventDefault();
                }
            }
        }
    };

    const callInfo = async () => {
        const data = await (
            await fetch(apiUrl + `/api/product/${targetProductId}`, {credentials: 'include'})
        ).json();
        if (data.product === null) {
            return navigate('/error')
        }
        setProductData(data);
        setRelatedProductsData(data.relatedProducts);
        setEventInfo(data.event);

        setEventBannerData(data.eventBanner);
    };

    useEffect(() => {
        bannerDataStore.callTopBannerInfo();
        callCartCount();
        callInfo();

        moveToAnchor(1000);

        const content = document.querySelector('.content-area');

        const handleHeaderPosition = () => {
            const defaultNavArea = document.getElementById('navigationPosition');
            const curScroll = pageInfoRef.current.scrollPosition();
            // const mobileHeader = document.getElementById('mainHeader');
            const header = document.querySelector('.mainHeaderContainer');
            const navigation = document.getElementById('NavigationTab');
            // const topBanner = document.getElementById('topBanner');

            if (window.innerWidth > breakpointDt) {
                header.style.transform = 'unset';
                navigation.style.transform = 'unset';
                navigation.style.marginTop = '0px';
                if (defaultNavRef.current < curScroll) {
                    navigation.style.position = CURRENT_MENU_POSITION_FIXED;
                    navigation.style.marginTop = '0px';
                    defaultNavArea.classList.add('visible');
                    // defaultNavArea.style.marginBottom = '56px';
                } else {
                    navigation.style.position = 'unset';
                    navigation.style.marginTop = '56px';
                    defaultNavArea.classList.remove('visible');
                }

                return;
            } else {
                if (scrollBeforeRef.current > curScroll && header && navigation) {
                    // setScrollDirection('up');
                    setScrollBefore(curScroll);
                    header.style.transform = 'unset';
                    navigation.style.transform = 'unset';
                    // topBanner.style.transform = 'unset';
                    if (defaultNavRef.current > curScroll) {
                        navigation.style.position = 'unset';
                        navigation.style.marginTop = '56px';
                        defaultNavArea.classList.remove('visible');
                    }
                } else if (scrollBeforeRef.current < curScroll) {
                    // setScrollDirection('down');
                    setScrollBefore(curScroll);

                    // if(curScroll  > ) 여기에 스크롤바가 네비게이션 처음 나온 위치 보다 아래일 때라고만 해주면 완벽할듯
                    // console.log(defaultNavRef.current, curScroll, defaultNavArea);
                    if (defaultNavRef.current < curScroll) {
                        navigation.style.position = CURRENT_MENU_POSITION_FIXED;
                        navigation.style.marginTop = '0';
                        defaultNavArea.classList.add('visible');

                        if (defaultNavRef.current < curScroll - 120) {
                            navigation.style.transform = `translateY(${-header.scrollHeight + 18}px)`;
                            // header.style.transform = `translateY(${-header.scrollHeight -topBanner.scrollHeight+ 19 }px)`;
                            header.style.transform = `translateY(${-header.scrollHeight + 19}px)`;
                            // topBanner.style.transform = `translateY(${-header.scrollHeight -topBanner.scrollHeight + 19 }px)`;
                        }
                    } else {
                        navigation.style.position = 'unset';
                        navigation.style.marginTop = '56px';
                        defaultNavArea.classList.remove('visible');
                    }
                }
            }
        };

        //window.addEventListener('resize', defineCurrentMenuPosition);

        //window.addEventListener('resize', menuTopHandler);
        //window.addEventListener('resize', menuBottomHandler);
        // document.querySelector('.menu-area .purchaseMenu-wrapper').addEventListener('click', ()=>{
        //   setTimeout(()=>{
        //     menuTopHandler();

        //   }, 0);
        // });

        // handleHeaderPosition();

        content.addEventListener('scroll', handleHeaderPosition);
        window.addEventListener('scroll', handleHeaderPosition);
        window.addEventListener('resize', handleHeaderPosition);

        return () => {
            content.removeEventListener('scroll', handleHeaderPosition);
            window.removeEventListener('scroll', handleHeaderPosition);
            window.removeEventListener('resize', handleHeaderPosition);
        };
        // smooth 스크롤 폴리필
    }, []);

    useEffect(() => {
        scrollBeforeRef.current = scrollBefore;
        const defaultNavPosition = document.getElementById('NavigationTab').offsetTop - 70;
        defaultNavRef.current = Math.max(defaultNavPosition, defaultNavRef.current || -1);

        // 높이가 아니라, 엘리먼트로
    }, [scrollBefore]);

    // upSell 컨텐츠 결정
    useEffect(() => {
        if (productData && productData.product.slug === 'a-card-5-pack') {
            setUpSellDiscountRate('-38%');
            setUpSellText1('더 넉넉하고 저렴하게 구매해보세요.');
            setUpSellText2(`패밀리 팩으로 구매하면, 
                        더욱 저렴한 가격으로 구매하실 수 있어요.`);
            setUpSellLink('/product/a-card-family-pack');
            setUpSellImage('/img/a_card_family_upsell.png');
            setUpSellButtonText('패밀리 팩 구매하러 가기');
        } else if (productData && productData.product.slug === 'a-card') {
            setUpSellDiscountRate('-25%');
            setUpSellText1('더 넉넉하고 저렴하게 구매해보세요.');
            setUpSellText2(`세트로 구매하면,
                        더욱 저렴한 가격으로 구매하실 수 있어요.`);
            setUpSellLink('/product/a-card-5-pack');
            setUpSellImage('/img/a_card_5_upsell.png');
            setUpSellButtonText('세트 구매하러 가기');
        }
    }, [productData]);

    // 스크롤에 따른 헤더 스타일 변화
    useEffect(() => {
        const navigation = document.getElementById('NavigationTab');
        const navigationPosition = document.getElementById('navigationPosition');
        const navigationOffsetTop = document.getElementById('navigationPosition2').offsetTop;
        const header = document.getElementById('mainHeader');
        const content = document.querySelector('.content-area');

        const handleNavigationPosition = () => {
            // 56이 아니라 헤더 높이로 잡아야 함
            const headerHeightMo =
                document.querySelector('.mainHeader')?.scrollHeight +
                document.getElementById('topBanner')?.scrollHeight;
            const headerHeightDt =
                document.querySelector('.mainHeader')?.scrollHeight +
                document.getElementById('topBanner')?.scrollHeight;
            const topBanner = document.getElementById('topBanner');

            if (window.innerWidth < breakpointDt) {
                navigation.style.top = `${headerHeightMo}px`;
                navigation.style.paddingTop = `${0}px`;
                navigationPosition.style.paddingTop = `${0}px`;
            } else {
                navigation.style.top = '0px';
                navigation.style.paddingTop = `${headerHeightDt}px`;
                navigation.style.marginTop = '0px';
                navigationPosition.style.paddingTop = `${headerHeightDt}px`;
            }

            if (window.innerWidth < breakpointDt) {
                if (window.scrollY > navigationOffsetTop - headerHeightMo) {
                    navigation.classList.remove('fixed-dt');
                } else {
                    navigation.classList.remove('fixed-mo');
                }
            } else {
                header.style.transform = 'unset';
                topBanner.style.transform = 'unset';
            }
        };

        const handleFloatingButtonShow = () => {
            const mobileButtons = document.querySelector('.mobilefloatingbutton-container');
            if (window.scrollY > 15) {
                mobileButtons.classList.add('visible');
            }
        };

        const handleScrollAction = () => {
            //       handleChangeHeaderColor();
            // if (window.innerWidth < breakpointDt) {
            //   handleNavigationPosition();
            // }
            handleNavigationPosition();
            // handleFloatingButtonShow();
        };

        // 탑베너 없어지는 경우 sticky 포지션 재조정이 필요함
        document.querySelector('.mainHeaderContainer').addEventListener('click', () => {
            setTimeout(() => {
                handleNavigationPosition();
            }, 200);
        });

        window.addEventListener('resize', handleNavigationPosition);
        window.addEventListener('scroll', handleScrollAction);
        content.addEventListener('scroll', handleScrollAction);

        return () => {
            window.removeEventListener('scroll', handleScrollAction);
            window.removeEventListener('resize', handleNavigationPosition);
            content.removeEventListener('scroll', handleScrollAction);
        };
    }, []);

    const makeDataForCart = (options) => {
        const res = [];

        options.forEach((option) => {
            res.push({
                product_id: option.product_id ? option.product_id : productData.product.id,
                variation_id: option.id,
                quantity: option.quantity,
            });
        });
        return res;
    };


    const cartOpenOrAction = async () => {
        console.log('cartOpenOrAction start', optionInfo);

        if (optionInfo.length === 0) {
            alert('옵션을 선택해야 합니다2');
        } else {
            setIsSpinnerShow(true);
            const dataToSend = makeDataForCart(optionInfo);

            const fetchResult = await fetchAndReceiveJSON({
                url: apiUrl + cartAddurl,
                method: 'POST',
                body: dataToSend,
            });

            if (fetchResult === "Login Required") {
                setLoginModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult === "Only One Purchased") {
                setOnePurchaseModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult === "One Purchased") {
                setCartModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult) {
                await callCartCount();
                setIsSpinnerShow(false);
                setModalShow(true);
            } else {
                setIsSpinnerShow(false);
            }
        }
    };

    const cartOpenOrAction2 = async (optionInfo2) => {
        console.log('cartOpenOrAction2 start', optionInfo2);
        if (optionInfo2.length === 0) {
            alert('옵션을 선택해야 합니다3');
        } else {
            setIsSpinnerShow(true);
            const dataToSend = makeDataForCart(optionInfo2);

            const fetchResult = await fetchAndReceiveJSON({
                url: apiUrl + cartAddurl,
                method: 'POST',
                body: dataToSend,
            });
            if (fetchResult === "Login Required") {
                setLoginModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult === "Only One Purchased") {
                setOnePurchaseModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult === "One Purchased") {
                setCartModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult) {
                await callCartCount();
                setIsSpinnerShow(false);
                setModalShow(true);
            } else {
                setIsSpinnerShow(false);
            }
        }
    };

    const convertThumbnailImages = (images) => {
        let imgList
        if (window.innerWidth >= breakpointDt_mid) {
            imgList = images.filter(img => !img.src.includes('mobile-'))
        } else {
            imgList = images.filter(img => !img.src.includes('pc-'))
        }
        return imgList
    }


    const buyNowAction = async () => {
        if (optionInfo.length === 0) {
            alert('옵션을 선택해야 합니다4');
        } else {
            const dataToSend = makeDataForCart(optionInfo);
            setIsSpinnerShow(true);
            // console.log(dataToSend, 'buynow data')
            const res = await fetch(apiUrl + '/api/cart/buynow', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(dataToSend),
            });
            const data = await res.json();

            if (data.status === "Login Required") {
                setIsSpinnerShow(false);
                setLoginModal(true);
            } else if (res.status === 200) {
                setIsSpinnerShow(false);
                window.location.href = '/cart';
            } else if (data.status === 'Only One Purchased') {
                setIsSpinnerShow(false);
                setOnePurchaseModal(true);
            } else if (data.status === "One Purchased") {
                setCartModal(true)
                setIsSpinnerShow(false);
            } else {
                alert(data.message);
                setIsSpinnerShow(false);
            }
        }
    };

    const infoRef = useRef(optionInfo);
    useEffect(() => {
        // console.log("optionInfo change to ", optionInfo);

        infoRef.current = optionInfo;

        setTimeout(() => {
            definePageInfo();
        }, 500);

        // console.log(optionInfo);

        calculateTotal();
    }, [optionInfo]);

    useEffect(() => {
        if (!productData.product.id) {
            return;
        }

        let isCustomTaps = false;

        let tabs = [];

        // if (!isCustomTaps) {
        //구매 혜택(이벤트) 탭 붙이기
        if (isLogin && C_COUPONS && C_COUPONS.length > 0) {
            tabs.push({
                tabName: '구매 혜택',
                targetDomId: 'event',
                moveWhenClicked: true,
            });
        } else if (eventInfo) {
            tabs.push({
                tabName: '구매 혜택',
                targetDomId: 'event',
                moveWhenClicked: true,
            });
        }

        for (let i in customTaps) {
            // console.log(productData?.product?.slug , customTaps[i].slug);
            if (customTaps[i].slug === productData?.product?.slug) {
                isCustomTaps = true;

                // console.log("true");
                for (let j in customTaps[i].tabs) {
                    tabs.push(customTaps[i].tabs[j]);
                }
                break;
            }
        }

        //default tap
        let defaultTabs = [];
        if (!isCustomTaps) {
            defaultTabs = [
                {
                    tabName: '제품 정보',
                    targetDomId: 'detail',
                    moveWhenClicked: true,
                },
            ];
        }

        tabs = [...tabs, ...defaultTabs];
        // }

        //reviewTab
        const reviewTab = {
            tabName: `리뷰 ${
                productData.product.rating_count ? `(${productData.product.rating_count})` : ''
            } `,
            targetDomId: 'review',
            moveWhenClicked: true,
        };

        tabs.push(reviewTab);

        //연관 상품
        if (relatedProductsData.length && relatedProductsData.length > 0) {
            tabs.push({
                tabName: '연관상품',
                targetDomId: 'related',
                moveWhenClicked: true,
            });
        }

        setTabInfo(tabs);
    }, [productData, eventInfo, relatedProductsData]);

    const totalPriceRef = useRef(0);

    useEffect(() => {
        totalPriceRef.current = totalPrice;
    }, [totalPrice]);
    useEffect(() => {
        // 히든네비게이션에 카트 넣기
        const menuCartIconWidthNumberDt = document.querySelector('.menuCartIconWidthNumberDt');
        const menuCartIconWidthNumberMo = document.querySelector('.menuCartIconWidthNumberMo');
        const naverpayButtonComponent1 = document.getElementById("iamport-naverpay-product-button1")
        const naverpayButtonComponent2 = document.getElementById("iamport-naverpay-product-button2")
        const naverpayButtonComponent3 = document.getElementById("iamport-naverpay-product-button3")
        const naverpayButtonComponent4 = document.getElementById("iamport-naverpay-product-button4")
        if (naverpayButtonComponent1 && naverpayButtonComponent2 && naverpayButtonComponent3 && naverpayButtonComponent4 && !makeNpayButtonFlag && productData?.product?.id) {
            // window.onload = () => {
            // 네이버 페이 버튼 만들기
            // console.log('--- onload  makeNpayButton~~ -- ');
            // console.log({ productData : productData,
            // optionInfo : optionInfo} );
            setMakeNpayButtonFlag(true)
            makeNpayButton({
                elemIds: [
                    'iamport-naverpay-product-button1',
                    'iamport-naverpay-product-button2',
                    'iamport-naverpay-product-button3',
                    'iamport-naverpay-product-button4',
                    // 'iamport-naverpay-product-button5',
                ],
                buttonKey: NpayButtonKey,
                buyAction: async () => {
                    const dataToSend = makeDataForCart(infoRef.current);
                    if (!dataToSend || dataToSend.length === 0) {
                        setCustomAlert('옵션을 선택 해주세요');
                        return;
                    }
                    buyNowNpayAction({
                        dataToSend: dataToSend,
                        setIsSpinnerShow: setIsSpinnerShow,
                    });
                },
                getProductsForNpayZzim: () => {
                    if (productDataRef) {
                        window.IMP.init(C_IAMPORT_CODE);
                        return getProductsForNpayZzim({
                            id: productDataRef.current.product?.id,
                            name: productDataRef.current.product?.name,
                            uprice: productDataRef.current.product?.price,
                            slug: productDataRef.current.product?.slug,
                            image: productDataRef.current.product?.thumbnail,
                        });
                    }
                },
                withZzim: true,
            });

            // 히든네비게이션에 카트 넣기
            const menuMoHeaderCartArea = document.querySelector('#header_menu_mo .menu-popup-control-mo');
            const menuDtHeaderCartArea = document.querySelector('#header_menu_dt .header-acc');
            if (menuCartIconWidthNumberDt) {
                menuCartIconWidthNumberDt.classList.remove('hidden');
            }
            if (menuCartIconWidthNumberMo) {
                menuCartIconWidthNumberMo.classList.remove('hidden');
            }
            if (menuMoHeaderCartArea) {
                menuMoHeaderCartArea.insertBefore(menuCartIconWidthNumberMo, menuMoHeaderCartArea.firstChild);
                menuDtHeaderCartArea.appendChild(menuCartIconWidthNumberDt);
            }
        }
        // }
        //console.log('--- ok makeNpayButton -- ')
    }, [productData]);

    // useEffect(() => {
    //   if (
    //     location.pathname === '/product/collins-incense' &&
    //     window.sessionStorage.getItem('showKakaoPopupInIncense') === null
    //   ) {
    //     window.sessionStorage.setItem('showKakaoPopupInIncense', 'true');
    //   } else if (
    //     location.pathname === '/product/collins-incense-giftset' &&
    //     window.sessionStorage.getItem('showKakaoPopupInGift') === null
    //   ) {
    //     window.sessionStorage.setItem('showKakaoPopupInGift', 'true');
    //   }
    // }, []);
    return (
        <SpinnerControl isSpinnerShow={isSpinnerShow}>
            <Helmet>
                <title>콜린스{productData.product?.name ? " - " + productData?.product?.name : ''}</title>
            </Helmet>
            {onePurchaseModal && <Modal
                text={`해당 제품은 1회 1개씩 구매 가능합니다.`}
                oneButtonText={`확인`}
                alignText={null}
                buttonText={null}
                buttonText2={null}
                buttonAction={null}
                buttonAction2={null}
                vertiCalButton={null}
                withClose={null}
                oneButton={true}
                oneButtonAction={() => setOnePurchaseModal(false)
                }
                closeAction={() => {
                    setOnePurchaseModal(false);
                }}
            />}
            {cartModal && <Modal
                text={`장바구니에 해당 제품이 이미 담겨 있습니다. (해당 제품은 1회에 1개씩만 구매 가능합니다.)`}
                buttonText={'확인'}
                buttonText2={'장바구니 보기'}
                buttonAction={() => setCartModal(false)}
                buttonAction2={() => navigate('/cart')}
                closeAction={() => {
                    setCartModal(false);
                }}
            />}
            {customAlert && (
                <Modal
                    text={customAlert}
                    buttonText="확인"
                    closeAction={() => {
                        setCustomAlert(null);
                    }}
                    alignText="center"
                    buttonAction={() => {
                        setCustomAlert(null);
                    }}
                />
            )}
            <Header
                callCartCount={callCartCount}
                setIsSpinnerShow={setIsSpinnerShow}
                topBannerData={{data: bannerDataStore.topBannerData}}
                topBannerEnabled={
                    bannerDataStore.topBannerData && bannerDataStore.topBannerData.enabled
                }
                isHeaderBgColorChange={true}
                leftContent={
                    <>
                        <HambergerForHiddenNav className="dt"/>
                        <div className="mo">
                            {document.referrer && document.referrer.indexOf('collinslife.co') != -1 ? (
                                <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        window.history.go(-1);
                                    }}
                                >
                                    <BackwordArrow/>
                                </div>
                            ) : (
                                <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        window.location.href = '/';
                                    }}
                                >
                                    <img src={GnbHomeIcon} alt=""/>
                                </div>
                            )}
                        </div>
                    </>
                }
                centerContent={null}
                rightContent={
                    <>
                        <div
                            className=" dt"
                            style={{
                                height: '40px',
                            }}
                            onClick={(e) => {
                                moveToLink('/cart', e);
                            }}
                        >
                            <CartWithNumberDt number={cartQuantity}/>
                        </div>
                        <div
                            className="flex  mo"
                            // style ={{display: 'flex'}}
                        >
                            <div
                                className=" "
                                style={{
                                    marginRight: '10px',
                                }}
                                onClick={(e) => {
                                    moveToLink('/cart', e);
                                }}
                            >
                                <CartWithNumberMo number={cartQuantity}/>
                            </div>
                            <HambergerForHiddenNav/>
                        </div>
                    </>
                }
            />

            {/* <div className="npayBtns" style={{ display: 'none' }}>
        <div id={'iamport-naverpay-product-button'} className="npay-button"></div>
        <div id={'iamport-naverpay-product-button2'} className="npay-button"></div> */}
            {/* <div id={'iamport-naverpay-product-button3'} className="npay-button"></div> */}
            {/* </div> */}

            <div className="product-page-wrapper">
                <div className="content-area">
                    {
                        productData.product.images[0].src !== '' ? (
                            <DetailImages imageData={filterThumbnailImageByFormat(productData.product.images)}
                                          soldOutStatus={productData.product.stock_status === "outofstock"}
                                          setLoadedThumbnail={() => setLoadedThumbnail(true)}
                            />
                        ) : (
                            <DetailImages imageData={undefined}
                                          soldOutStatus={productData.product.stock_status === "outofstock"}
                            />
                        )}
                    {eventBannerData && eventBannerData.img && (
                        <div className="evnetBannerUnerSlider dtBanner">
                            {/* <img
            // style = {{
            //   cursor: 'pointer',

            // }}
            // onClick = {()=>{
            //   moveToLink(`${eventBannerData.link}`);
            // }}
            src = {}
          /> */}
                            <PreloadImage
                                className="eventImgContent"
                                style={{
                                    backgroundColor: '#f7f7ed',
                                    // paddingTop: '75%',
                                    // height: sliderHeight
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '1200px',
                                }}
                                src={eventBannerData.img}
                            />
                        </div>
                    )}
                    <div className="info-wrapper">
                        <div className="mobile-menu">
                            <ProductPurchaseMenu
                                setLoginModal={setLoginModal}
                                isLogin={isLogin}
                                isCanbuyNow={productData.shipping_available_today}
                                productData={productData}
                                optionQuantityData={optionInfo}
                                onOptionSelect={adjustIndividualOptionCount}
                                resetOptionInfo={resetOptionInfo}
                                totalPrice={totalPrice}
                                isForProductNpay={productData.product.is_npay_available}
                                cartOpenOrAction={cartOpenOrAction}
                                buyNowAction={buyNowAction}
                                slug={productData.product.slug}
                                isInProductpage={true}
                            />

                            {eventBannerData && eventBannerData.img && (
                                <div className="evnetBanner">
                                    <img src={eventBannerData.img}/>
                                </div>
                            )}
                        </div>
                        <div id="navigationPosition">
                            <NavigationTab
                                tabInfo={tabInfo}
                                isVisibleInDt={true}
                                pageInfo={pageInfoRef.current}
                                isIncense={isHasCustomTapProduct(productData.product.slug)}
                            />
                        </div>
                        <div id="navigationPosition2"></div>
                        <div id="NavigationTab">
                            <div className="widthSetter">
                                <NavigationTab
                                    tabInfo={tabInfo}
                                    isVisibleInDt={true}
                                    pageInfo={pageInfoRef.current}
                                    isIncense={isHasCustomTapProduct(productData.product.slug)}
                                />
                            </div>
                        </div>
                        <div>
                            <Suspense fallback={<div></div>}>
                                <DetailInfo slug={productData.product.slug} eventInfo={eventInfo}
                                            isLogin={isLogin}
                                            user={user}
                                            setDetailPageLoaded={() => setDetailPageLoaded(true)}/>
                            </Suspense>
                        </div>

                        <div id="review" className="infoSection">
                            <Suspense fallback={<div></div>}>
                                <Reviews
                                    productId={productData.product.id}
                                    productThumbnail={productData.product.thumbnail}
                                    averageRating={productData.product.average_rating}
                                    title={productData.product.title}
                                    galleryImages={productData.product.images}
                                    subtitle={productData.product.sub_title}
                                    pageInfo={pageInfo}
                                    isLogin={isLogin}
                                    // reviewWritable = {productData.product.review_writable}
                                    // myReview = {productData.product.myReview}
                                />
                            </Suspense>
                        </div>

                        <div id="related" className="infoSection">
                            <Suspense fallback={<div></div>}>
                                {detailPageLoaded && <RelatedProducts
                                    relatedProducts={
                                        relatedProductsData.length && relatedProductsData.length > 0
                                            ? relatedProductsData
                                            : false
                                    }
                                />}
                            </Suspense>
                        </div>

                        {/* <Footer /> */}
                        <FooterIncenseVer className="productPage"/>
                    </div>
                    <div className="mobile-button" style={{display: isUpSellingPopUp ? 'none' : ''}}>
                        <ProductDetailMobileFloatingButtons
                            productData={productData}
                            setLoginModal={setLoginModal}
                            onOptionSelect={adjustIndividualOptionCount}
                            totalPrice={totalPrice}
                            optionQuantityData={optionInfo}
                            resetOptionInfo={resetOptionInfo}
                            cartOpenOrAction={cartOpenOrAction2}
                            buyNowAction={buyNowAction}
                            isForProductNpay={productData.product.is_npay_available}
                            slug={productData.product.slug}
                            isSimple={productData.product && productData.product.type === 'simple'}
                            isLogin={isLogin}
                        />
                    </div>
                </div>
                {loginModal && <Modal
                    text={`해당 제품은 로그인 상태에서만 구매 가능합니다.`}
                    buttonText={`가입/로그인하기`}
                    buttonText2={`확인`}
                    buttonAction={() => {
                        moveToLink('/signup/menu');
                    }}
                    closeAction={() => {
                        setLoginModal(false);
                    }}
                    buttonAction2={() => {
                        setLoginModal(false);
                    }} alignText={undefined} oneButton={undefined} oneButtonText={undefined} oneButtonAction={undefined}
                    vertiCalButton={undefined} withClose={undefined}/>}

                {modalShow && (
                    <Modal
                        text={'선택한 상품이 \n 장바구니에 잘 담겼어요.'}
                        closeAction={() => {
                            setModalShow(false);
                        }}
                        buttonText2={'장바구니 보기'}
                        buttonText={'계속 쇼핑할게요'}
                        buttonAction2={() => {
                            navigate('/cart');
                        }}
                        buttonAction={() => {
                            setModalShow(false);
                        }}
                    />
                )}

                <div className="menu-area">
                    <div className="menu-area-inner fixed">
                        <ProductPurchaseMenu
                            setLoginModal={setLoginModal}
                            isCanbuyNow={productData.shipping_available_today}
                            productData={productData}
                            optionQuantityData={optionInfo}
                            onOptionSelect={adjustIndividualOptionCount}
                            totalPrice={totalPrice}
                            isLogin={isLogin}
                            isButtonInclude={true}
                            isForProductNpay={productData.product.is_npay_available}
                            resetOptionInfo={resetOptionInfo}
                            cartOpenOrAction={cartOpenOrAction}
                            cartAddurl={cartAddurl}
                            buyNowAction={buyNowAction}
                            isDesktop={true}
                            slug={productData.product.slug}
                            isInProductpage={true}
                            eventBannerData={eventBannerData}
                        />
                    </div>
                </div>
            </div>

            <Suspense fallback={<div></div>}>
                <PopupWrapper
                    popUpCloseAction={() => {
                        setIsUpSellingPopUp(false);
                    }}
                    className={`upSellingPopUp ${isUpSellingPopUp ? 'visible' : 'invisible'}`}
                    aleadyRendered={true}
                    aleadyRenderedVisibility={isUpSellingPopUp}
                >
                    <div className="upSellContent">
                        <div className="discountBadge">최대 {upSellDiscountRate} 할인</div>

                        <div className="upSellText1">
                            {upSellText1.split('\n').map((line, i) => (
                                <span key={`text${i}`}>
                  {line}
                                    <br/>
                </span>
                            ))}
                        </div>

                        <div className="upSellText2">
                            {upSellText2.split('\n').map((line, i) => (
                                <span key={`text${i}`}>
                  {line}
                                    <br/>
                </span>
                            ))}
                        </div>
                        {upSellImage && <img src={upSellImage} className="upSellImage"></img>}
                        <CTAButton
                            className="upSellButton"
                            onClick={() => {
                                location.href = upSellLink;
                            }}
                        >
                            {upSellButtonText}
                        </CTAButton>

                        <span
                            className=" upSellClose mo"
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                setIsUpSellingPopUp(false);
                            }}
                        >
              <WhiteCloseIcon/>
            </span>
                        <span
                            className="upSellClose dt"
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                setIsUpSellingPopUp(false);
                            }}
                        >
              <MediumBrownCloseIcon/>
            </span>
                    </div>
                </PopupWrapper>
            </Suspense>
        </SpinnerControl>
    );
};

export default ProductPage;

