import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import './DropdownSimple.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { UpArrow, DownArrow } from '../../../assets/Arrows.jsx';
import { OUTOFSTOCK_OPTION_SELECT_TEXT } from '../../constants/constants.js';

const Dropdown2: FunctionComponent<{
  placeholder?: string;
  menuItems?: any;
  selectAction?: CallableFunction;
  isOptionOpen?: boolean;
  setHomeMobileCartHeight?: CallableFunction;
  selectedOption?: any;
  setSelectedOption?: CallableFunction;
  zIndex?: number;
}> = ({
  placeholder,
  menuItems,
  selectAction,
  isOptionOpen,
  setHomeMobileCartHeight,
  selectedOption,
  zIndex,
}) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(
    /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  );
  const dropdownMenuRef = useRef<HTMLDivElement>();
  // const [isDropdownOpen, setIDropdownOpen] = useState(isOptionOpen);

  const isOnMenu = (flag) => {
    setIsShowMenu(flag);

    //TODO 영향 확인
    if (setHomeMobileCartHeight) {
      // setHomeMobileCartHeight(flag);
    }
  };

  const closeOnSelection = () => {
    setIsShowMenu(false);
    document.removeEventListener('click', () => {
      isOnMenu(false);
    });
    // sizeControl && sizeControl(false);
    // setIsOptionOpen && setIsOptionOpen(false);
    // setIDropdownOpen(false);
    setHomeMobileCartHeight && setHomeMobileCartHeight(false);
  };

  useEffect(() => {
    if (isOptionOpen) {
      setIsShowMenu(isOptionOpen);
    }
    setHomeMobileCartHeight && setHomeMobileCartHeight(isOptionOpen);
  }, []);

  return (
    <div className="dropdown-wrapper-simple" style={{ zIndex: zIndex }}>
      <div
        className="dropdown-default"
        data-testid="dropdownDt"
        onClick={() => {
          isOnMenu(!isShowMenu);
        }}
      >
        <span> {selectedOption ? selectedOption.name : placeholder} </span>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {isShowMenu ? <UpArrow /> : <DownArrow />}
        </div>
      </div>

      {isShowMenu && menuItems && (
        <div
          data-testid="dropdownMewnu"
          className="dropdown-menu multiOptions"
          // style={{ position: 'absolute' }}
          ref={dropdownMenuRef}
        >
          {menuItems.map((menuItem, i) => {
            return (
              <div
                className={`dropdown-item ${isMobile ? 'mobile' : ''}  `}
                data-testid="dropdownItem"
                onClick={() => {

                  if (menuItem.soldout) {
                    alert(OUTOFSTOCK_OPTION_SELECT_TEXT);
                    return;
                  }
                  closeOnSelection();
                  selectAction({
                    id: menuItem.key,
                    name: menuItem.value,
                  });
                }}
                key={i}
              >
                <div className="imgAndName">
                  <span>{menuItem.value}</span>
                </div>
                {menuItem.soldout && <div className="outOfStock">일시품절</div>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
