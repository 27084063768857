import React, {
    FunctionComponent,
    ReactNode,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {apiUrl, breakpointDt, breakpointDt_mid} from '../../constants/constants';
// import { breakpointDt } from '../../constants/constants';
import TopBanner, {TopBannerProps} from '../TopBanner/TopBanner.component';
import './Header.scss';
import {fetchAndReceiveJSON, moveToLink} from '../../utils/utils';
import {productDataType} from '../../@types/types';
import {AppContext} from '../../context/AppContext';
import {observer} from 'mobx-react';
import HamburgerIcon from "../../../img/gnb_menu_32.svg"
import SearchIcon from "../../../img/search-icon-home.svg"
import HomeIcon from "../../../img/search-icon.svg"
import {SearchIconColor} from "../../../assets/SearchIcon";

const Header: FunctionComponent<{
    isNoHeaderMove?: boolean;
    leftContent?: ReactNode;
    centerContent?: ReactNode;
    rightContent?: ReactNode;
    topBannerEnabled?: boolean;
    topBannerData?: TopBannerProps;
    isHeaderBgColorChange?: boolean;
    isSectionNavSticky?: boolean;
    callCartCount?: CallableFunction;
    setIsSpinnerShow?: CallableFunction;
    isInHome?: boolean;
    disableSearch?: boolean;
    isSearchIconColor?: string;
}> = observer(
    ({
         leftContent = '',
         centerContent = '',
         rightContent = '',
         topBannerEnabled = false,
         topBannerData = {
             data: {title: '', url: '', text_color: '', background_color: ''},
             setIsTopBannerEnabled: false,
         },
         isHeaderBgColorChange = false,
         isSectionNavSticky = false,
         callCartCount,
         setIsSpinnerShow,
         isInHome = false,
         isNoHeaderMove,
         disableSearch = false,
         isSearchIconColor
     }) => {


        const [scrollBefore, setScrollBefore] = useState(window.scrollY);
        const [isSearchOpen, setIsSearchOpen] = useState<boolean>();
        const [isSearchResultOpen, setIsSearchResultOpen] = useState<boolean>();

        const mobileHeaderRef = useRef<HTMLDivElement>(null);
        const headerRef = useRef<HTMLDivElement>(null);
        const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
        const [GNBRandomNumber, setGNBRandomNumber] = useState<number>(0)
        const [searchResultData, setSearchResultData] = useState<Array<productDataType>>([]);
        const [searchWord, setSearchWord] = useState<string>('');
        //const [sortCriteria, setSortCriteria] = useState<string>('기본순');

        const [searchedItemCnt, setSearchedItemCnt] = useState<string | number>();
        const [isEmpty, setIsEmpty] = useState<boolean>(false);

        const ProductSearch = useMemo(
            () => React.lazy(() => import('./../ProductSearch/ProductSearch.component')),
            []
        );
        const SearchResults = useMemo(
            () => React.lazy(() => import('./../SearchResults/SearchResults.component')),
            []
        );

        const handleChangeHeaderColor = () => {
            const mobileHeader = mobileHeaderRef.current;
            // const desktopHeader = document.querySelector('#header_product_dt');
            if (isInHome) {
                if (window.scrollY > 15) {
                    mobileHeader?.classList.add('bg-gradient');
                } else {
                    mobileHeader?.classList.remove('bg-gradient');
                }
            } else {
                if (window.scrollY > 15) {
                    mobileHeader?.classList.add('bg-color');
                } else {
                    mobileHeader?.classList.remove('bg-color');
                }
            }
        };
        // 고민, 페이지 별로 어떤 스토어를 쓸지 어떻게 정하지...
        //
        const {spinnerStore, homePageStore} = React.useContext(AppContext);
        const {setIsBlackIcons, isBlackIcons} = homePageStore;

        useEffect(() => {
            if (isHeaderBgColorChange) {
                handleChangeHeaderColor();
                window.addEventListener('scroll', handleChangeHeaderColor);
            }
            // setGNBRandomNumber(getRandomInt(0, 3))
            return () => {
                window.removeEventListener('scroll', handleChangeHeaderColor);
            };
        }, []);
        // TODO:  SearchResult 켜져 있으면 뒤로 가기 버튼이 스테이트를 변화 시키는데 사용됨

        useEffect(() => {
            if (isSearchResultOpen) {
                history.pushState('', '', '');
                window.onpopstate = () => {
                    history.go(0);
                    // setIsSearchResultOpen(false);
                };
            }
        }, [isSearchResultOpen]);

        // const { collectionPageStore } = React.useContext(AppContext);

        useEffect(() => {
            // scroll 위치에 따라서 transform이 안되게 해야 함, 맨 처음 그 위치를 기억하면 좋을텐데..
            //
            const handleHeaderPosition = () => {
                if (isNoHeaderMove) {
                    return;
                }
                // return;
                const curScroll = window.scrollY;
                const header = headerRef.current;

                const sectionNavigation = document.getElementById('sectionNavigation');
                const collectionControl = document.getElementById('collectionControl');
                const promotionControl = document.getElementById('promotion-header-controller');
                // console.log(isSectionNavSticky);
                const collectionTop = collectionControl && collectionControl.getBoundingClientRect().top;
                const promotionTop = promotionControl && promotionControl.getBoundingClientRect().top;
                if (window.scrollY < 50) {
                    if (header) {
                        header.style.transform = 'unset';
                    }
                    if (sectionNavigation) {
                        sectionNavigation.style.transform = 'unset';
                    }
                    if (collectionControl) {
                        collectionControl.style.transform = 'unset';
                    }
                    if (promotionControl) {
                        promotionControl.style.transform = 'unset';
                    }
                    return;
                }

                if (window.innerWidth >= breakpointDt) {
                    header.style.transform = 'unset';
                    if (sectionNavigation) {
                        sectionNavigation.style.transform = 'unset';
                    }
                    if (collectionControl) {
                        collectionControl.style.transform = 'unset';
                    }
                    if (promotionControl) {
                        promotionControl.style.transform = 'unset';
                    }
                    return;
                } else if (window.innerWidth >= breakpointDt_mid && isInHome && !promotionControl) {
                    header.style.transform = 'unset';
                    if (sectionNavigation) {
                        sectionNavigation.style.transform = 'unset';
                    }
                    if (collectionControl) {
                        collectionControl.style.transform = 'unset';
                    }
                    if (promotionControl) {
                        promotionControl.style.transform = 'unset';
                    }
                    return;
                } else if (!isSearchOpen && !isSearchResultOpen) {
                    if (scrollBefore > curScroll) {
                        setScrollBefore(curScroll);
                        header.style.transform = 'unset';
                        if (sectionNavigation && isSectionNavSticky) {
                            sectionNavigation.style.transform = `translateY(${header.scrollHeight - 1}px)`;
                        } else if (sectionNavigation && !isSectionNavSticky) {
                            sectionNavigation.style.transform = 'unset';
                        }

                        if (collectionControl && collectionTop < header.scrollHeight) {
                            collectionControl.style.transform = `translateY(${header.scrollHeight - 1}px)`;
                        } else if (collectionControl && collectionTop > header.scrollHeight) {
                            collectionControl.style.transform = 'unset';
                        }
                        if (promotionControl && promotionTop < header.scrollHeight) {

                            promotionControl.style.transform = `translateY(${header.scrollHeight - 1}px)`;
                        } else if (promotionControl && promotionTop > header.scrollHeight) {
                            promotionControl.style.transform = 'unset';
                        }

                    } else if (scrollBefore < curScroll) {
                        setScrollBefore(curScroll);
                        if (sectionNavigation && isSectionNavSticky) {
                            sectionNavigation.style.transform = 'unset';
                        } else if (sectionNavigation && !isSectionNavSticky) {
                            sectionNavigation.style.transform = 'unset';
                        }
                        header.style.transform = `translateY(${-header.scrollHeight}px)`;

                        if (collectionControl) {
                            collectionControl.style.transform = 'unset';
                        }
                        if (promotionControl) {
                            promotionControl.style.transform = 'unset';
                        }
                    }
                }
            };
            // 프로덕트 페이지 일때는 안하도록 하기
            const productPage = document.querySelector('.product-page-wrapper');

            if (!productPage) {
                window.addEventListener('scroll', handleHeaderPosition);
            }

            return () => {
                window.removeEventListener('scroll', handleHeaderPosition);
            };
        }, [scrollBefore, isSearchOpen, isSearchResultOpen, isNoHeaderMove]);

        // isSearchOpen, isSearchResultOpen

        const bodyOverFlowRef = useRef<string>();
        useEffect(() => {
            const header = headerRef.current;
            if (isSearchOpen || isSearchResultOpen) {
                header.style.zIndex = '42';
                if (document.body) {
                    bodyOverFlowRef.current = document.body.style.overflow || 'scroll';
                    document.body.style.overflow = 'hidden';
                }
            } else if (!isSearchOpen && !isSearchResultOpen) {
                header.style.zIndex = '39';
                if (document.body) {
                    document.body.style.overflow = bodyOverFlowRef.current;
                }
            }
        }, [isSearchOpen, isSearchResultOpen]);

        const callSearhResult = useCallback(
            async (forceStr?: string) => {
                const res = await fetchAndReceiveJSON({
                    url: apiUrl + `/api/v2/search?keyword=${encodeURI(forceStr ? forceStr : searchWord)}`,
                    method: 'GET',
                    body: '',
                });
                if (res) {
                    setSearchResultData(res.data.data);
                    setSearchedItemCnt(res.data.pageinfo.itemCnt);
                    if (res.data.data.length === 0) {
                        setIsEmpty(true);
                    } else {
                        setIsEmpty(false);
                    }
                } else {
                    setIsEmpty(true);
                }
            },
            [searchWord]
        );

        useEffect(() => {
            if (isSearchResultOpen || isSearchOpen) {
                const kakaoChat = document.getElementById('ch-plugin');
                if (kakaoChat) {
                    kakaoChat.style.opacity = '0';
                }
                document.body.style.overflow = 'hidden';
            }
        }, [isSearchResultOpen, isSearchOpen]);

        useEffect(() => {
            const hamburger: HTMLImageElement = document.querySelector('#homeHamburgerIconSearchOpened');
            const logo: HTMLImageElement = document.querySelector('#homeLogoIconSearchOpened');
            const cart: HTMLElement = document.querySelector('#homeCartIconSearchOpened');
            const cartGTag: HTMLElement = document.querySelector('#cartIconGTag');
            const mobileHeader = mobileHeaderRef.current;

            if (isInHome && isSearchResultOpen) {
                setIsBlackIcons(true);
                hamburger.src = HamburgerIcon;
                logo.src = 'https://fly.gitt.co/collins/img/logo_b.svg';
                if (cart) {
                    cart.style.backgroundColor = '#604448';
                    cart.style.color = '#ffffff';
                }
                if(cartGTag){
                    cartGTag.style.stroke = '#604448';
                }
                mobileHeader?.classList.remove('bg-gradient');
                // logo.src = 'https://fly.gitt.co/collins/img/logo_b.svg';
            }
        }, [isSearchResultOpen]);

        return (
            <div
                className="mainHeaderContainer"
                style={{
                    transition: 'transform 500ms',
                    background: isSearchResultOpen ? '#fffff5' : '',
                }}
                ref={headerRef}
            >
                <div id="topBanner">{topBannerEnabled && <TopBanner {...topBannerData} />}</div>
                <div
                    className="mainHeader"
                    style={{
                        backgroundColor: isSectionNavSticky ? '#fffff5' : '',
                        boxShadow:
                            isSectionNavSticky && window.innerWidth > breakpointDt_mid
                                ? '0 2px 6px 0 rgba(0, 0, 0, 0.06)'
                                : '',
                    }}
                    id="mainHeader"
                    ref={mobileHeaderRef}
                >
                    <div className="leftEl" data-testid="leftEl">
                        {leftContent}
                    </div>
                    <div className="centerEl" data-testid="centerEl">
                        {centerContent}
                    </div>
                    <div className="rightEl" data-testid="rightEl">
                        {/*{((isInHome && !isSearchResultOpen)) && (*/}
                        {/*    <div style={{}}*/}
                        {/*         onClick={(e) => moveToLink('/collection/fragrance', e)} className="incenseBtn">*/}
                        {/*        인센스 구매하기*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {!disableSearch && <div
                            className="searchIcon"
                            onClick={() => {
                                setIsFirstRender(false);
                                setIsSearchOpen(!isSearchOpen);
                            }}
                        >
                            {isSearchIconColor ?
                                <SearchIconColor color={isSearchIconColor}/> :
                                <img
                                    id='rightElSearchIcon'
                                    style={{width: '100%'}}
                                    src={
                                        isInHome && !isBlackIcons ? SearchIcon : HomeIcon
                                    }
                                    alt=""
                                />}
                        </div>}
                        {rightContent}
                    </div>
                </div>
                <div
                    className={`searchBar ${
                        !isFirstRender && isSearchOpen
                            ? 'visible'
                            : !isFirstRender && !isSearchOpen
                                ? 'hidden'
                                : null
                    }`}
                >
                    <Suspense fallback={null}>
                        {!isFirstRender && isSearchOpen && <ProductSearch
                            searchCall={(str) => {
                                callSearhResult(str);
                                setIsSearchOpen(false);
                                setIsSearchResultOpen(true);
                            }}
                            closeCall={() => {
                                setIsSearchOpen(false);
                            }}
                            setSearchWord={setSearchWord}
                            searchWord={searchWord}
                        />}
                    </Suspense>
                </div>
                <div className={`searchResults ${isSearchResultOpen ? 'visible' : 'hidden'}`}>
                    <Suspense fallback={null}>
                        <SearchResults
                            searchWord={searchWord}
                            setSearchWord={setSearchWord}
                            searchResultData={searchResultData}
                            callSearhResult={callSearhResult}
                            searchedItemCnt={searchedItemCnt}
                            callCartCount={callCartCount}
                            setIsSpinnerShow={setIsSpinnerShow ? setIsSpinnerShow : spinnerStore.setIsSpinnerShow}
                            isEmpty={isEmpty}
                        />
                    </Suspense>
                </div>
            </div>
        );
    }
);

export default Header;
